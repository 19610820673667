<template>
  <div id="beneficiaries-tab" class="beneficiaries-container">
    <div class="beneficiaries-header">
      <div class="beneficiaries-title">
        Favorecido
        <Icon tooltip="Crie e gerencie favorecidos">
          <v-help-circle class="icon stroke" />
        </Icon>
      </div>
      <div class="gap-action-buttons no-print">
        <b-button
          class="wh-button"
          variant="primary"
          @click="openBeneficiaryModal()"
        >
          Cadastrar favorecido
        </b-button>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <b-form-group class="form-group">
          <label for="search">Nome / Razão social</label>
          <b-form-input
            autocomplete="off"
            id="search"
            v-model="filters.name"
            placeholder="Buscar nome ou razão social"
            debounce="500"
          />
        </b-form-group>
      </div>

      <div class="col-3 multiselect-container">
        <b-form-group id="field_category">
          <label for="field_category">Estado</label>
          <v-checkbox-select
            id="procedureType"
            placeholder="Selecionar"
            label="Todos os estados"
            :value="filters.state"
            :options="stateOptions"
            v-on:onChange="changeSelectedstate"
          />
        </b-form-group>
      </div>

      <div class="col-3">
        <b-form-group id="field_category">
          <label for="field_category">Cidade</label>
          <b-form-input
            autocomplete="off"
            id="search"
            v-model="filters.city"
            placeholder="Buscar"
            debounce="500"
          />
        </b-form-group>
      </div>
    </div>
    <v-beneficiaries-table 
      v-if="beneficiaries.length"
      :beneficiaries="beneficiaries"
      @edit="openBeneficiaryModal"
      @delete="deleteBeneficiaryModal"
    />
    <div 
    v-else 
    class="no-data" 
    >
      Nenhum dado a ser exibido
    </div>
    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        first-number
        last-number
      />
    </div>
    <v-beneficiary-modal 
      :beneficiary="selectedBeneficiary" 
      @onUpdate="getBeneficiaries"
    />
  </div>
</template>
<script>
import brazilStates from '@/utils/states'

export default {
  name: 'beneficiaries',
  components: {
    Icon: () => import('@/components/General/Icon'),
    'v-help-circle': () => import('@/assets/icons/help-circle.svg'),
    'v-checkbox-select': () => import('@/components/CheckboxSelect.vue'),
    'v-beneficiaries-table': () => import('@/modules/financial/components/BeneficiariesTable.vue'),
    'v-beneficiary-modal': () => import('@/modules/financial/modals/BeneficiaryModal.vue'),
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      beneficiaries: [],
      selectedBeneficiary: null,
      stateOptions: brazilStates.map(state => state.initial),
      filters: {
        name: null,
        state: [],
        city: null,
        paginate: 10,
      },
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
    }
  },
  async mounted(){
    await this.getBeneficiaries()
  },
  methods: {
    async getBeneficiaries(){
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getBeneficiaries(this.clinic.id, this.filters, this.currentPage)
        this.currentPage = response.data.current_page
        this.perPage = response.data.per_page
        this.totalItems = response.data.total
        const beneficiaries = response.data.data
        this.beneficiaries = beneficiaries.map( beneficiary => {
          return {
              'id': beneficiary.id,
              'name': beneficiary.name,
              'number': beneficiary.number,
              'person_id': beneficiary.person_id,
              'email': beneficiary.email,
              'type': beneficiary.type,
              'phone': beneficiary.phone,
              'observations': beneficiary.observations,
              'address': {
                'id': beneficiary.address ? beneficiary.address.id : null,
                'city': beneficiary.address ? beneficiary.address.city : null,
                'state': beneficiary.address ? beneficiary.address.state : null,
                'number': beneficiary.address ? beneficiary.address.number : null,
                'neighborhood': beneficiary.address ? beneficiary.address.neighborhood : null,
                'address': beneficiary.address ? beneficiary.address.address : null, 
                'complement': beneficiary.address ? beneficiary.address.complement : null,
                'zipCode': beneficiary.address ? beneficiary.address.zipCode : null,    
            }
          }
        })
      } catch(error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    changeSelectedstate(newValue){
      this.filters.state = newValue
    },
    openBeneficiaryModal(beneficiary=null){
      this.selectedBeneficiary = JSON.parse(JSON.stringify(beneficiary))
      this.$bvModal.show('beneficiary-modal');
    },
    async deleteBeneficiaryModal(beneficiary) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
          Você tem certeza que deseja remover o favorecido <span>${beneficiary.name}</span>?
          </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(async res => {
        if (res.isConfirmed) {
          await this.deleteBeneficiary(beneficiary)
        }
      })
    },
    async deleteBeneficiary(beneficiary){
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteBeneficiary(beneficiary.id)
      } catch(error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
        this.$toast.success('Favorecido excluído com sucesso!')
        this.getBeneficiaries()
      }
    }
  },
  watch: {
    currentPage: {
      handler: async function () {
        await this.getBeneficiaries()
      }
    },
    filters: {
      handler: async function () {
        await this.getBeneficiaries()
      },
      deep: true
    },
  }
}
</script>

<style lang="scss" scoped>
  .no-data {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .multiselect-container {
    .selectedOptions {
      height: auto;
    }
  }
  .beneficiaries-header {
    widows: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
  }
  .beneficiaries-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    font-family: 'Red Hat Display';
    font-weight: 500;
    font-size: 18px;
    .icon {
      height: 24px;
      width: 24px;
      stroke: var(--neutral-500);
    }
  }
</style>